import React, { useState } from "react";
import { graphql } from "gatsby";
import SsImage from "../components/utilities/ss-image";
import { Helmet } from "react-helmet";
import { getYoutubeId } from "../components/utilities/media";

const Project = ({
  data: {
    smartsheet: { project },
    wp: {
      themeSettings: { themeSettings },
    },
  },
}) => {
  const [activeLightbox, setActiveLightbox] = useState(false);

  const triggerLightbox = (image, alt) => {
    setActiveLightbox({
      image: image,
      alt: alt,
    });
  };

  let youtubeId = null;
  if (project.youtubeVideoLink) {
    youtubeId = getYoutubeId(project.youtubeVideoLink, "youtube");
  }

  return (
    <>
      <Helmet>
        <title>
          {project.projectName} - {themeSettings.siteMetaTitle}
        </title>
        {themeSettings.customCssOverrides && (
          <style>{themeSettings.customCssOverrides}</style>
        )}
      </Helmet>
      <div className="projects-single-page template-project">
        {project.images?.length > 0 ? (
          <section className="page-banner">
            <img
              className="page-banner-cover"
              src={project.images[0].sourceUrl}
              alt={project.projectName}
            />
          </section>
        ) : (
          <>
            <br className="d-none d-md-block" />
            <br className="d-none d-md-block" />
            <br />
          </>
        )}

        <section className="page-title">
          <div className="container">
            <div className="row">
              {(project.projectName || project.projectDescription) && (
                <div className="col-lg-6 pe-lg-5 project-title">
                  {project.projectName && (
                    <div className="title">
                      <h1>{project.projectName}</h1>
                    </div>
                  )}
                  {project.projectDescription && (
                    <div className="content">
                      <p>{project.projectDescription}</p>
                    </div>
                  )}
                </div>
              )}
              <div className="col-lg-6 ">
                <div className="project-details">
                  <div className="content details text-white">
                    <h3>Details</h3>
                    {project.projectType && (
                      <p>
                        <strong>+ Project Type:</strong> {project.projectType}
                      </p>
                    )}
                    {(project.city || project.state) && (
                      <p>
                        <strong>+ Location:</strong> {project.city}
                        {project.city && project.state && ", "}
                        {project.state}
                      </p>
                    )}
                    {project.yearBuilt && (
                      <p>
                        <strong>+ Year Completed:</strong> {project.yearBuilt}
                      </p>
                    )}
                    {project.product && (
                      <p>
                        <strong>+ Products Used:</strong> {project.product}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {project.images?.length > 0 || project.youtubeVideoLink ? (
          <section className="section project-files">
            <div className="container">
              <div className="row mb-5">
                {project.images?.length > 0 && (
                  <>
                    {project.images.map((image) => (
                      <div className="col-lg-4" key={image.sourceUrl}>
                        <div
                          className="project-img-trigger"
                          onClick={() =>
                            triggerLightbox(
                              image.sourceUrl,
                              project.projectName
                            )
                          }
                          data-bs-toggle="modal"
                          data-bs-target="#images-lightbox"
                        >
                          <img
                            className="d-block img-fluid rounded image project-files-img"
                            src={image.sourceUrl}
                            alt={project.projectName}
                          />
                        </div>
                      </div>
                    ))}
                  </>
                )}

                {project.youtubeVideoLink && (
                  <div className="col-12">
                    <div className="ratio ratio-16x9">
                      <iframe
                        loading="lazy"
                        className="rounded img-fluid cwi-iframe"
                        src={`https://www.youtube.com/embed/${youtubeId}?playlist=${youtubeId}`}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        ) : (
          <>
            <br />
            <br />
            <br />
            <br />
          </>
        )}
      </div>

      <div
        id="images-lightbox"
        className="modal fade"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {activeLightbox && (
                <img
                  className="d-block mw-100 h-auto"
                  src={activeLightbox.image}
                  alt={activeLightbox.alt}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const pageQuery = graphql`
  query Project($id: String!) {
    smartsheet {
      project(id: $id) {
        id
        slug
        projectNumber
        organizationType
        projectName
        yearBuilt
        sportType
        projectType
        projectTypeNotes
        product
        icon
        projectAddress
        city
        state
        latitude
        longitude
        projectDescription
        youtubeVideoLink
        images {
          sourceUrl
        }
      }
    }
    wp {
      themeSettings {
        themeSettings {
          siteMetaTitle
          customCssOverrides
        }
      }
    }
  }
`;

export default Project;
